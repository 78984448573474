import React, { FC } from 'react'
import closeSvg from '../../assets/images/svgs/close.svg'
import backIcon from '../../assets/images/svgs/arrow-black.svg'
import { useAppSelector } from '../../store/hooks'
import penny from '../../assets/images/penny.png'
import { User } from '../../types/api'
import './styles.scss'

interface UserMenuHeaderProps {
    onClose: () => void
    isBackArrow?: boolean
}

const UserMenuHeader: FC<UserMenuHeaderProps> = ({ onClose, isBackArrow }) => {
    const user = useAppSelector((state) => state.auth.user) as User

    return (
        <div className="menu__porfile-info">
            <div className="menu__porfile-info__content">
                <div
                    className="menu__porfile-info__backarrow"
                    onClick={onClose}
                >
                    <img src={isBackArrow ? backIcon : closeSvg} />
                </div>
                <div className="menu__porfile-info__center-content">
                    <img
                        className="menu__porfile-info_image"
                        src={user?.profile_photo?.url}
                    />
                    <div className="menu__porfile-info_name-points">
                        <div className="menu__porfile-info_name-points__name">
                            {user.first_name}
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu__porfile-info__bottom-line"></div>
        </div>
    )
}

export default UserMenuHeader
