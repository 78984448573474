import { useEffect, useState } from 'react'

import arrow_back from '../../assets/images/svgs/arrow-back-black.svg'
import { Button } from '../../components/button'
import TextField from '../../components/text-field'
import { getAccessTokenSignUp, resetPassword } from '../../services/api'
import { CLIENT_ID, CLIENT_SECRET } from '../../services/config'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { validateEmail } from '../../utils/_validateEmail'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const { access_token } = useAppSelector((state) => state.auth)
    const {t} = useTranslation()

    useEffect(() => {
        getAccessTokenSignUp({
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'client_credentials',
            scope: 'ios',
        })
    }, [])

    const dispatch = useAppDispatch()
    const _goBack = () => {
        dispatch(changeScreen('Sign In'))
    }

    const _setvalue = (SetState: (e: string) => void, e: string) => {
        SetState(e)
        setError('')
    }

    const _resetPassword = () => {
        if (!email.trim()) {
            setError('Please fill all fields')
        } else if (!validateEmail(email)) {
            setError('Incorrect email')
        } else {
            resetPassword(email, access_token.access_token)
            dispatch(changeScreen('Start Screen'))
        }
    }
    return (
        <div className="forgot-container">
            <div className="forgot-container__hero-wrapper">
                <div
                    className="forgot-container__close-wrapper"
                    onClick={_goBack}
                >
                    <img src={arrow_back} />
                </div>
            </div>
            <div className="forgot-container__body">
                <h3 className="forgot-container_title">
                    {t("forgot_your_password")}
                </h3>
                <p className="forgot-container_info">
                    {t("dont_worry")}
                </p>
                <TextField
                    label={t("email")}
                    value={email}
                    setValue={(e) => _setvalue(setEmail, e)}
                />
                {error && (
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>
                )}
            </div>
            <div className="forgot-container__bottom-wrapper">
                <Button
                    variant="white-outlined"
                    text={t("reset_password")}
                    onClick={_resetPassword}
                />
            </div>
        </div>
    )
}

export default ForgotPassword
