import { useEffect } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import ShopScreen from './screens/shop-screen/index'
// import { changeScreen } from './store/auth/auth-action'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { getUserProfile } from './services/api'

function App() {
    const { user, screen } = useAppSelector((state) => state.auth)
    const { access_token } = useAppSelector((state) => state.auth).access_token
    const dispatch = useAppDispatch()

    useEffect(() => {
        const s = document.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.innerHTML = `!function(f,b,e,v,n,t,s)
    { }if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
      n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '474576234337814');
    fbq('track', 'PageView');

      var vglnk = {key: '56578e265f6ffbff30978fe07ef5803d'};
  (function(d, t) {
    var s = d.createElement(t);
      s.type = 'text/javascript';
      s.async = true;
      s.src = '//cdn.viglink.com/api/vglnk.js';
    var r = d.getElementsByTagName(t)[0];
      r.parentNode.insertBefore(s, r);
  }(document, 'script'));

  <noscript><img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=474576234337814&ev=PageView&noscript=1" /></noscript>`
        document.body.appendChild(s)
    }, [])

    useEffect(() => {
        const setUserInfo = async () => {
            if (access_token) {
                await getUserProfile(access_token, 'Menu Screen')
            }
        }

        setUserInfo()
    }, [])

    return (
        <div
            id="scrollableDiv"
            style={{
                position: 'relative',
                width: '100vw',
                height: '100vh',
                overflow: 'auto',
            }}>
            <BrowserRouter>
                <ShopScreen />
            </BrowserRouter>
        </div>
    )
}

export default App
