import React from 'react'
import { useNavigate } from 'react-router-dom'

import { changeScreen } from '../../store/auth/auth-action'
import { setIsInScreen } from '../../store/following/following-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setActiveCorporateId } from '../../store/impact/impact-action'
import { CorporatePartner } from '../../types/api'

import './style.scss'

interface Props {
    // data: CorporatePartner
    data: any
    text: string
    isFiltersShow: boolean
    affiliatePercent: number | null
}
const Photos: React.FC<Props> = ({ data, affiliatePercent }) => {
    const { user } = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const openDetails = () => {
        if (user) {
            dispatch(setIsInScreen(false))
            dispatch(setActiveCorporateId(data.id))
            dispatch(changeScreen('Corporate Details'))
            navigate('/brand/' + data.name.replaceAll(' ', '_'))
        } else {
            dispatch(changeScreen('Start Screen'))
            navigate('/brand/' + data.name.replaceAll(' ', '_'))
        }
    }

    const handleSiteClick = () => {
        const path = `https://${data?.site}`
        if (user) {
            window.open(path, '_blank', 'noopener,noreferrer')
        } else {
            navigate('/menu')
        }
    }

    return (
        <div className="photo">
            {data.photo?.url ? (
                <img
                    onClick={openDetails}
                    className="photo-background"
                    src={data.photo?.url}
                />
            ) : (
                <div className="photo-content" onClick={openDetails}>
                    <div className="photo-header"></div>
                    <div className="photo-container">
                        {data.logo ? (
                            <img src={data.logo} />
                        ) : (
                            <h4>{data.name}</h4>
                        )}
                    </div>
                </div>
            )}

            {affiliatePercent && (
                <div className="photo-percentage">{affiliatePercent}</div>
            )}

            <div className="photo-site">
                <button onClick={handleSiteClick}>{data?.site}</button>
            </div>
        </div>
    )
}

export default Photos
