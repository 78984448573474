import React from 'react'
import { Button } from '../button'
import { useTranslation } from 'react-i18next'
import './styles.scss'

interface Props {
    hideInfo: () => void
}

const SponsorInfo: React.FC<Props> = ({ hideInfo }) => {
    
    const {t} = useTranslation()
    
    return (
        <div className="sponsor-info-container">
            <div className="sponsor-info-container__modal">
                <h3 className="sponsor-info-container_title">
                    {t("available_balance")}
                </h3>
                <p className="sponsor-info-container_subtitle">
                    {t("not_all_points_spent")}
                </p>
                <p className="sponsor-info-container_desc">
                    {t("transaction_clearance")}
                </p>
                <p className="sponsor-info-container_desc">
                    {t("once_points_cleared")}
                </p>
                <br />
                <Button variant="white-outlined" text="OK" onClick={hideInfo} />
            </div>
        </div>
    )
}

export default SponsorInfo
