import { useEffect } from 'react'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const ThanksForDonate = () => {
    const { activeProject } = useAppSelector((state) => state.impact)
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        setTimeout(() => {
            dispatch(changeScreen('My Impact'))
        }, 3000)
    })
    return (
        <div className="thanks-container">
            <div className="thanks-container__content">
                <h3 className="thanks-container_thanks-title">
                   {t("congratulations_contribution")}
                </h3>
                <h4 className="thanks-container_thanks-name">
                    {activeProject.name}
                </h4>
            </div>
        </div>
    )
}

export default ThanksForDonate
