import React, { FC } from 'react'
import closeSvg from '../../assets/images/svgs/close.svg'
import icon from '../../assets/images/svgs/loggin-button.svg'
import person from '../../assets/images/svgs/person.svg'
import langSvg from '../../assets/images/svgs/lang.svg'
import { Button } from '../button'
import { useAppSelector } from '../../store/hooks'
import MenuLinks from '../menu-links'
import { LanguageDropdown } from '../language-dropdown'
import { useTranslation } from 'react-i18next'

import './styles.scss'

interface HeaderMenuMobileProps {
    isOpen: boolean
    onClose: () => void
    _handlePress: () => void
}

export const HeaderMenuMobile: FC<HeaderMenuMobileProps> = ({
    isOpen,
    onClose,
    _handlePress,
}) => {
    const { user } = useAppSelector((state) => state.auth)
    const {t} = useTranslation()

    if (!isOpen) {
        return <></>
    }
    return (
        <div className="mobile-menu__container">
            <div className="mobile-menu__header">
                <button className="mobile-menu__close" onClick={onClose}>
                    <img src={closeSvg} alt="close" />
                </button>
                <Button
                    className={`mobile-menu__user ${!user && 'login'}`}
                    text={user ? null : t("log_in")}
                    image={user ? user.profile_photo.url : person}
                    hideImg={!user}
                    variant="transparent"
                    onClick={() => {
                        onClose()
                        _handlePress()
                    }}
                    icon={user ? icon : ''}
                />
            </div>
            <div className="mobile-menu__content">
                <div className="mobile-menu__list">
                <MenuLinks/>
                </div>
                <LanguageDropdown isMobile={true}/>
            </div>
        </div>
    )
}
