import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Plus from '../../assets/images/svgs/PlusForBrand.svg'
import Unfollow from '../../assets/images/svgs/unfollowPopUp.svg'
import SettingsHeader from '../../components/settings-header'
import { getFollowings, unfollowFeatures } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import {
    setFollowings,
    setIsInScreen,
    _Unfollow,
} from '../../store/following/following-action'
import { Followings } from '../../store/following/types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setActiveCorporateId } from '../../store/impact/impact-action'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/spinner'
import './styles.scss'


const Following = () => {
    const dispatch = useAppDispatch()
    const { followings } = useAppSelector((state) => state.following)
    const { access_token } = useAppSelector((state) => state.auth)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);

    const _closeModal = () => {
        navigate('/')
    }
    const _getFollowings = async () => {
        setLoading(true)
        const data1 = await getFollowings(access_token.access_token, 0, 0)
        dispatch(setFollowings(data1))
        const data2 = await getFollowings(
            access_token.access_token,
            followings.metadata.total + 2,
            0
        )
        dispatch(setFollowings(data2))
        setLoading(false);
    }
    const _unfollow = async (id: string) => {
        await unfollowFeatures(access_token.access_token, id)
        dispatch(_Unfollow(id))
        _getFollowings()
    }
    const _openDetails = (id: string, name: string) => {
        dispatch(setIsInScreen(true))
        dispatch(setActiveCorporateId(id))
        dispatch(changeScreen('Corporate Details'))
        navigate('/brand/' + name.replaceAll(' ', '_'))
    }
    useEffect(() => {
        dispatch(setFollowings({} as Followings))
        _getFollowings()
    }, [])


    if(loading) return <Spinner/>

    return (
        <div id="scrollableDiv" className="follow-screen">
            <SettingsHeader screen="Menu Screen" text={t("following")} />
            <p className="follow-screen_count">
                {t("you_have")}{' '}
                {followings?.metadata?.total ? followings?.metadata?.total : 0}{' '}
                {t("saved_brands")}
            </p>
            <div id="scrollableDiv" className="follow-screen__container-global">
                <div id="scrollableDiv" className="follow-screen__container">
                    {followings?.results?.map((e: any) => {
                        return (
                            <div
                                key={e.id}
                                className="follow-screen__brand"
                                onClick={() => {
                                    _openDetails(e.id, e.name)
                                }}
                            >
                                <img
                                    className="follow-screen__brand_img"
                                    src={e.photo.url}
                                />
                                <p className="follow-screen__brand_text">
                                    {e.name}
                                </p>
                                <img
                                    onClick={(el) => {
                                        el.stopPropagation()
                                        _unfollow(e.id)
                                    }}
                                    src={Unfollow}
                                    className="follow-screen__brand_unfollow"
                                />
                            </div>
                        )
                    })}
                    <div
                        className="follow-screen__new-brand"
                        onClick={_closeModal}
                    >
                        <p className="follow-screen__new-brand_title">
                            {t("add_new_brand")}
                        </p>
                        <img
                            className="follow-screen__new-brand_plus"
                            src={Plus}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Following
